import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogUrl = "https://selbysellssd.com/turning-primary-residence-into-investment-property";

  return (
    <LayoutWrapper
      title="Turning Primary Residence Into an Investment Property"
      desc="You might consider converting your home to a rental. Read on to learn some of the do’s & don’ts of turning your primary residence into an investment property."
      headerLogo="../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              The Do’s and Don’ts of Turning Your Primary Residence Into an Investment Property
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By The Selby Team
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  February 15, 2023
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_The Do’s and Don’ts of Turning Your Primary Residence Into an Investment Property.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                After you’ve built up some equity in your home, you might consider converting it to
                rental property.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Making the switch can be a great way to earn passive income if you plan to buy a new
                home to serve as your primary residence, but it’s important to know what’s involved
                in making the change.{" "}
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Do
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Converting your home to rental property requires planning. Here are some tips to
                help you get started.
              </p>

              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Make Sure You Can Use Your Home as Investment Property
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Before converting your primary residence to a rental property, you must confirm that
                it's even an option. Then, check with your lender to ensure that the terms of your
                loan allow you to use your property in this way.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                You'll also want to review the HOA rules in your community since they may have
                policies that limit your ability to use the property for rental income.
              </p>

              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Change Your Insurance Coverage
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                You'll no longer be able to use your traditional homeowner's insurance once you rent
                to tenants. Instead, shop for landlord insurance that provides sufficient property
                and liability coverage. This policy protects you against property damages and injury
                claims from your tenants or guests.
              </p>

              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Network With Professionals
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                While you might think you can handle your investment property on your own, some
                issues require a professional's touch.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                For example, you might need a real estate agent to market a rental vacancy or
                develop a business relationship with a general contractor to get repairs done. You
                might add attorneys, electricians, and plumbers to your contacts list along the way.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Don't
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Here are a few common mistakes to avoid when converting a primary residence to a
                rental property.
              </p>

              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Forget to Research Landlord/Tenant Laws
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you don't try to learn your area's landlord/tenant laws, you could put yourself
                at legal risk.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Research your obligations as the landlord and determine how anti-discrimination laws
                will apply to you. An attorney can help you meet your legal obligations, but you
                should still understand the relevant laws.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Start Without a Financial Safety Net
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The advantage of converting your home into an investment property is that you're
                already familiar with the basic condition of the property. Even so, roof damage or a
                failing water heater might surprise you.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As such, you should ensure enough savings to cover any repair emergencies. In
                addition, being prepared is essential for keeping your property habitable.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Put Off Your Obligations After Converting Your Primary Residence
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                In addition to having the financial resources to cover repairs, you'll need to
                ensure the work gets done promptly. This might involve using reliable contractors,
                so you don't have to set time aside to do the work yourself.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Give your tenants an easy way to contact you and make rent payments. Better
                communication will help you maintain more positive relationships.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Interested in Buying a New Home? We Can Help
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you want to buy a home in San Diego, connect with a top-rated team of realtors
                who listen, are readily available, and will work tirelessly for you.{" "}
                <ContactSlideoutLink text="Contact The Selby Team" /> to get started on finding your
                new home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
